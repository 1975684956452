export const useDownloadFile = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const file = await response.blob();
  const fileUrl = URL.createObjectURL(file);

  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', fileName);
  link.setAttribute('target', '_blank');
  link.click();

  URL.revokeObjectURL(fileUrl);
  link.remove();
};
